$ {
    const { has, get } = require("@ebay/retriever");
    const global = get(input, "options.global", {});
    const model = input.model || {};
    const layerModules = (model.VAS_SPOKE || model.VAS_INTERSTITIAL_MODULE) || { isEmpty: true };
    const layerCTA = model.VAS_CTA || { isEmpty: true };
    const layerRadiusSearch = model.VAS_RADIUS_SEARCH || { isEmpty: true };
    const vasTitle = model.VAS_TITLE || { isEmpty: true };
    const layerVasMeta = model.VAS_META || { isEmpty: true };
    const language = global.language || global.lang || 'en';
    const country = global.country || 'US';
    const mapConfig = input.mapConfig || {};

    const {
        itemCard,
        whatsIncluded,
        getInstallerList,
        howItWorks,
        vasForm,
        mapLink,
        mapPostalCodeLink,
        distanceLabel,
        locationForm,
        installerForm,
        sortForm,
        uxComponentName
    } = layerModules;

    let {
        title,
        closeIcon
    } = layerModules;
    
    const {
        postalCode,
        radius,
        showLoading,
        showNotice,
        showMap,
        showLocation,
        installerList,
        selectedInstaller,
        selectedVehicle,
        mapCenterIcon,
        showInstaller,
        statusMessage,
        installerScreenCTA,
        appointmentModel,
        loadingScreen,
        sortV2,
        vehicleModules
    } = state;

    const isSpoke = uxComponentName && uxComponentName === "VAS_SPOKE_V2";

    let ctasToShow = installerScreenCTA;
    let disablePrimaryButton = !selectedInstaller;
    let showMapNow = showMap;

    // Dynamic Class names
    const redesignClassName = get(layerVasMeta, "schemaVersion.INSTALLATION", "") === "INSTALLATION_V2" ? " vas-tire-installation__installation_v2" : "" ;

    // appointment screen
    const isAppointmentScreen = appointmentModel != null && !appointmentModel.isEmpty;

    if(isAppointmentScreen) {
        title = get(appointmentModel, "VAS_TITLE.title", {});
        closeIcon = get(appointmentModel, "VAS_CLOSE_ICON", {});
        showMapNow = false;
        ctasToShow = get(appointmentModel, "VAS_CTA", {});
        let appointmentInvalid = !(state.appointmentSelectedDate  && state.appointmentSelectedDate.length > 0 && state.appointmentSelectedSlot)
        disablePrimaryButton = appointmentInvalid || state.personalDataDisableCta;
    }

// vehicle screen    
    const isVehicleScreen = vehicleModules != null && !vehicleModules.isEmpty;

    if(isVehicleScreen) {
        title = get(vehicleModules, "VAS_TITLE.title", {});
        closeIcon = get(vehicleModules, "VAS_CLOSE_ICON", {});
        showMapNow = false;
        ctasToShow = get(vehicleModules, "VAS_CTA", {});
        disablePrimaryButton = !selectedVehicle;
    }

    const showLayer = !layerModules.isEmpty || isAppointmentScreen || isVehicleScreen;
    const hideMap =  !showMapNow && !isAppointmentScreen && !isVehicleScreen;
}

<macro name="renderFindInstaller">
   <find-installer 
        model=layerRadiusSearch 
        postal-code-value=postalCode
        radius-value=radius
        show-drawer=showLocation
        on-open-drawer("handleDrawerOpen")
        on-value-change("handleValueChange")
        on-get-installers("getInstallers")
    />
</macro>

<if(showLayer)>
        <div id=(isSpoke ? "vas-spoke-container" : "vas-interstitial-container")
            class=`${showMapNow? "vas-tire-installation__map" : "vas-tire-installation"}${redesignClassName}`>
        
        <header model=closeIcon on-close("dialogClose", isSpoke)>
            <@header-left>
                <if(showMapNow)>
                    <ebay-icon-button
                        class="vas-tire-installation__postal-code-button"
                        aria-label=mapPostalCodeLink.accessibilityText
                        on-click("handleDrawerOpen", { location: true })>
                        <ebay-location-24-icon/>
                        <!-- Debug :- SVG not loading through the ebay UI-->
                        <div hidden aria-hidden="true">
                            <svg viewBox="0 0 16 22" id="icon-location">
                                <path d="M8 0a8 8 0 018 8c0 1.9-.47 3.296-1.496 4.764l-.218.301-1.487 1.817c-.773.953-1.641 2.048-2.077 2.69-.506.746-1.108 2.015-1.788 3.786-.328.854-1.536.856-1.866.003l-.274-.69c-.583-1.436-1.102-2.487-1.545-3.142l-.296-.417c-1.046-1.428-3.166-3.954-3.258-4.077l-.199-.276C.47 11.293 0 9.901 0 8a8 8 0 018-8zm0 2a6 6 0 00-6 6c0 1.469.333 2.47 1.134 3.612l.167.23c.04.054 2.36 2.817 3.416 4.296l.188.27c.302.446.617 1.012.949 1.702l.143.304.14-.297c.323-.674.633-1.23.93-1.668l.195-.28c1.065-1.486 3.382-4.246 3.436-4.319l.167-.23C13.666 10.473 14 9.468 14 8a6 6 0 00-6-6zm0 2a4 4 0 110 8 4 4 0 010-8zm0 2a2 2 0 100 4 2 2 0 000-4z"></path>
                            </svg>
                        </div>
                        ${postalCode}
                    </ebay-icon-button>
                </if>
                <else-if(mapConfig.enabled && hideMap)>
                    <ebay-fake-link on-click("handleMap") disabled=(installerList.length === 0)>
                        <ux-textspans model=(mapLink && mapLink.textSpans) />
                    </ebay-fake-link>
                </else-if>
                <else-if(isAppointmentScreen)>
                    <div></div>
                </else-if>
            </@header-left>
            <@title>
                <h2 class="header-title" hidden=showMapNow>
                    <ux-textualdisplay model=title />
                </h2>
            </@title>
        </header>
        <if(mapConfig.enabled && showMapNow)>
            <map
                center=mapCenterIcon
                radius=radius
                model=installerList
                country=country
                language=language
                selected-installer=selectedInstaller
                on-installer-click("handleInstallerClick")
                apiKey=mapConfig.key
            />
            <if(showLocation)>
                <if(!has(layerRadiusSearch,'isEmpty'))>
                   <renderFindInstaller/>
                </if>
                <else>
                    <find-area
                        postal-code-value=postalCode
                        radius-value=radius
                        model={
                            radius: layerModules.radius,
                            postalCode: layerModules.postalCode,
                            getInstallerList,
                            distanceLabel,
                            locationForm
                        }
                        show-drawer
                        on-open-drawer("handleDrawerOpen")
                        on-get-installers("getInstallers")
                    />
                </else>
            </if>
            <if(showInstaller && selectedInstaller)>
                <installer-list
                    installer-form=installerForm
                    language=language
                    model=[selectedInstaller]
                    show-drawer
                    on-installer-click("handleInstallerClick") />
            </if>
        </if>
        <else>
            <if(isAppointmentScreen)>
                <div class="vas-tire-installation__scrollable">
                    <appointment model=appointmentModel on-vas-appointment-change("handleAppointmentChange") 
                        on-vas-personal-data-change("handleAppointmentLicenseChange")/>
                </div>    
            </if>
            <else-if(isVehicleScreen)>
                <div class="vas-tire-installation__scrollable">
                    <vehicle-finder model=vehicleModules isMobile=true on-ti-vehicle-finder-selection('handleVehicleChange')/>
                </div>              
            </else-if>
            <else>
                <div class="vas-tire-installation__scrollable">
                <if(howItWorks)>
                    <how-works model=howItWorks on-learn-more-click("tracking")/>
                </if>
                <item-card model=itemCard />
                
                <div class="vas-tire-installation__installer-form">
                    <if(!has(layerRadiusSearch,'isEmpty'))>
                        $ {
                            let template = get(layerRadiusSearch, 'formSummary.initialSummaries[0].textSpans[1].template', '');
                            const installationWith = get(layerRadiusSearch, 'formSummary.initialSummaries.[0]', {});
                            const shipDirectly = get(layerRadiusSearch, 'formSummary.initialSummaries.[1]', {});
                            const installationWithOnly = Object.assign({}, installationWith);
                            installationWithOnly.textSpans = installationWithOnly.textSpans.slice(0, 1);
                            if (template) {
                                template = template.replace('${radius}', radius);
                                template = template.replace('${postalCode}', postalCode);
                            }
                        }
                        
                        <div class="find-installer-redesign"> 
                            <div class="find-installer-choose">
                                <if(!has(vasTitle,'isEmpty'))>
                                    <h2 class="vas-tire-installation__title"> <ux-textualdisplay model=vasTitle.title /> </h2>
                                </if>
                                    
                                <p class="find-installer-choose__installation">
                                   <ebay-fake-link on-click("handleDrawerOpen", { location: true })>
                                        <ux-textualdisplay model=installationWithOnly/>
                                        <span class="ux-textspans--PSEUDOLINK">
                                            ${template}
                                        </span>
                                    </ebay-fake-link>
                                    <ux-textualdisplay model=shipDirectly/>
                                </p>
                            </div>
                        
                            <whats-included model=whatsIncluded />
                        </div>
                        
                    </if>
                    <else>
                        <whats-included model=whatsIncluded />
                        <if(distanceLabel)>
                            <div class="find-area__main">
                                <p class="find-area__installation-label">
                                    <ux-textualdisplay model=distanceLabel.label />
                                </p>
                                <ebay-fake-link on-click("handleDrawerOpen", { location: true })>
                                    $ {
                                        let template = get(distanceLabel, 'secondaryLabel.textSpans.0.template', '');
                                        if (template) {
                                            template = template.replace('${radius}', radius);
                                            template = template.replace('${postalCode}', postalCode);
                                        }
                                    }
                                    <span>
                                        ${template}
                                    </span>
                                </ebay-fake-link>
                            </div>
                        </if>
                    </else>
                </div>
                <if(sortV2)>
                    <sort model=sortV2 sort-form=sortForm key="sort" on-process-sort('handleSort') />
                </if>
                <if(showLoading)>
                    <loading-indicator />
                </if>
                <else>
                    <if(showNotice)>
                        $ const message = get(statusMessage, 'message', {});

                        <ebay-section-notice
                            a11y-text=get(message, "additionalText.0.accessibilityText")
                            status=(message.messageType === "INFO" ? "information" : "attention")>
                                <ux-textualdisplay model=get(message, "additionalText.0", {}) />
                                <!-- Debug :- SVG not loading through the ebay UI-->
                                <div hidden aria-hidden="true">
                                    <svg>
                                        <symbol viewBox="0 0 16 16" id="icon-attention-filled-16">
                                            <path d="M8 0a8 8 0 100 16A8 8 0 008 0zm0 12a1 1 0 110-2 1 1 0 010 2zm1-4a1 1 0 11-2 0V5a1 1 0 112 0v3z"></path>
                                        </symbol>
                                        <symbol viewBox="0 0 16 16" id="icon-information-filled-16">
                                            <path d="M8 0a8 8 0 100 16A8 8 0 008 0zm1 11a1 1 0 01-2 0V8a1 1 0 112 0v3zM8 6a1 1 0 110-2 1 1 0 010 2z"></path>
                                        </symbol>
                                    </svg>
                                </div>
                        </ebay-section-notice>
                    </if>
                    <else>
                        <installer-list
                            key="installer-list"
                            language=language
                            model=installerList
                            selected-installer=selectedInstaller
                            on-installer-click("handleInstallerClick") />
                    </else>
                </else>
            </div>
            </else>
            <if(ctasToShow && !ctasToShow.__isEmpty)>
                <ctas
                    fluid
                    model=ctasToShow
                    disable-primary=disablePrimaryButton
                    addon-id=(selectedInstaller && selectedInstaller.paramValue)
                    isBusy=state.isBusy
                    processingActionName=state.processingActionName
                    on-click("buttonClick") />
            </if>
            <if(!has(layerRadiusSearch,'isEmpty'))>
                <renderFindInstaller/>
            </if>
            <else>
                <find-area
                    postal-code-value=postalCode
                    radius-value=radius
                    model={
                        radius: layerModules.radius,
                        postalCode: layerModules.postalCode,
                        getInstallerList,
                        distanceLabel,
                        locationForm
                    }
                    show-drawer=showLocation
                    on-open-drawer("handleDrawerOpen")
                    on-value-change("handleValueChange")
                    on-get-installers("getInstallers")
                />
            </else>
        </else>
    </div>
</if>
